<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-03-13 09:31:19
 * @LastEditTime: 2023-06-21 14:05
 * @Descripttion: 【内容】通知公告
-->
<style lang="scss" scoped>
.notice {
    @include innerPage(56px 34px 48px 15px);
    @include pageTitle(0);
    @include defalutTable(calc(100% - 38px), 20px);
    @include pageFoot;

    .page-title {
        .search-form {
            width: 558px;
            margin-left: auto;
            margin-right: 20px;

            .el-form,
            .el-form-item {
                flex-grow: 1;
            }
        }
    }
}

.dialog-container {
    width: calc(100% + 20px);
    max-height: 380px;
    box-sizing: border-box;
    padding: 0 20px 0 18px;
    margin: 34px 0 48px;
    overflow: hidden;
    overflow-y: auto;

    .receive-crowd {
        margin-top: 18px;
    }

    .el-divider {
        margin-bottom: 28px;
    }

    .el-form-item:last-child {
        margin-bottom: 0;
    }
}
</style>

<template>
    <section class="notice">
        <div class="page-title">
            <h3>通知公告</h3>
            <div class="search-form">
                <el-form inline :model="searchForm">
                    <el-form-item>
                        <el-input v-model.trim="searchForm.query_word" maxlength="100" placeholder="输入关键字">
                            <span slot="prefix" class="iconfont">&#xe61c;</span>
                        </el-input>
                    </el-form-item>
                </el-form>
                <el-button type="custom_primary" @click="getData('search')" size="medium">查询</el-button>
            </div>
            <el-button class="light" type="custom_primary" size="medium" @click="showDialog">
                新增消息+
            </el-button>
        </div>
        <div class="table-box">
            <div class="table-inner" ref="tableInner">
                <el-table :data="tableData" height="100%">
                    <el-table-column align="center" prop="plsch_messen_title" label="消息标题" min-width="20%" />
                    <el-table-column align="center" prop="create_name" label="创建人" min-width="8%" />
                    <el-table-column align="center" prop="create_time" label="创建时间" min-width="14%" />
                    <el-table-column align="center" label="操作" min-width="20%">
                        <template slot-scope="scope">
                            <el-button type="custom_primary" size="mini" plain
                                :disabled="scope.row.plsch_messen_status == 20"
                                @click="messageDetail(scope.row.plsch_messen_id)">
                                编辑
                            </el-button>
                            <el-button type="custom_success" size="mini" plain
                                :disabled="scope.row.plsch_messen_status == 20"
                                @click="messagePublish(scope.row.plsch_messen_id)">
                                {{ scope.row.plsch_messen_status == 20 && '已发布' || '发布' }}
                            </el-button>
                            <el-button type="custom_danger" :disabled="scope.row.plsch_messen_status == 20"
                                @click="messageRemove(scope.row.plsch_messen_id)" size="mini" plain>删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="table-foot custom-foot">
                <customPagination :current='tablePage.pageindex' :total="tablePage.total" @pageChange="flippingPage" />
            </div>
        </div>
        <el-dialog :title="noticeForm.plsch_messen_id && '编辑公告' || '新增公告'" width="790px" :visible.sync="noticeDialog"
            @close="hideDialog">
            <div class="dialog-container">
                <el-form class="page-form" label-width="68px" :rules="messageRules" ref="noticeForm" :model="noticeForm">
                    <el-form-item label="接收群体" prop="sccla_grades">
                        <el-cascader placeholder="请选择班级" ref="cascader" v-model="noticeForm.sccla_grades"
                            :options="gradeClass" :props="{ multiple: true, value: 'sccla_id', label: 'sccla_name' }"
                            clearable collapse-tags @change="cascaderChange($event, 'cascader')" />
                    </el-form-item>
                    <el-form-item prop="plsch_messen_scope">
                        <div class="receive-crowd">
                            <el-checkbox-group v-model="noticeForm.plsch_messen_scope">
                                <el-checkbox label="1">学生</el-checkbox>
                                <el-checkbox label="2">家长</el-checkbox>
                                <el-checkbox label="3">授课老师</el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </el-form-item>
                    <el-form-item label="标题" prop="plsch_messen_title">
                        <el-input v-model.trim="noticeForm.plsch_messen_title" maxlength="100" placeholder="请输入标题" />
                    </el-form-item>
                    <el-form-item label="内容" class="editor" prop="plsch_messen_content">
                        <div class="ueditor">
                            <!-- <try-tinymce v-model="noticeForm.plsch_messen_content" :width="630"></try-tinymce> -->
                            <try-editor v-model="noticeForm.plsch_messen_content" :width="630" />
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="foot-flex">
                <el-button type="custom_info" size="small" @click="hideDialog">
                    取 消
                </el-button>
                <el-button type="custom_primary" size="small" :disabled="btnload[1]" @click="handleConfirm(2)"
                    v-loading="btnload[1]">
                    确 定
                </el-button>
                <el-button style="margin-left:auto" type="custom_success" size="small" :disabled="btnload[0]"
                    @click="handleConfirm(1)" v-loading="btnload[0]">
                    发 布
                </el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import ueditorcom from "@comp/ueditorcom"
import customPagination from "@comp/customPagination"
import { mapState } from "vuex";
import { messageList, messageRemove, messageEdit, messageAdd, messagePublish, messageInfo } from "@api/content"
export default {
    name: 'content_notice',
    components: {
        ueditorcom,
        customPagination
    },
    data() {
        return {
            tableData: [],
            tablePage: {
                pageindex: 1,
                total: 0,
            },
            searchForm: {},
            noticeDialog: false,
            noticeForm: { plsch_messen_scope: [] },
            btnload: [false, false],
            messageRules: {
                plsch_messen_title: [{ required: true, message: "请输入标题", trigger: "blur" }],
                plsch_messen_content: [{ required: true, message: "请输入通知内容", trigger: "blur" }],
                plsch_messen_scope: [{ type: "array", required: true, message: "请选择范围", trigger: "change" }],
                sccla_grades: [{ type: "array", required: true, message: "请选择接收群体", trigger: "change" }],
            },
        };
    },
    computed: {
        ...mapState('common', ['gradeClass'])
    },
    created() {
        this.getData();
    },
    methods: {
        /** 初始化列表数据 */
        async getData(val) {
            if (val == "search") this.tablePage.pageindex = 1;
            let params = {
                ...this.searchForm,
                pageindex: this.tablePage.pageindex
            }
            const { data: res } = await messageList(params);
            this.tableData = res.data;
            this.tablePage.total = res.allcount;
        },
        /** 切换页码 */
        flippingPage(val) {
            this.tablePage.pageindex = val;
            this.getData();
        },
        /** 获取通知详情 */
        async messageDetail(id) {
            const { data } = await messageInfo(id);
            let formKeys = ['plsch_messen_title', 'plsch_messen_scope', 'plsch_messen_content', 'sccla_grades', 'plsch_messen_id'];
            let form = {};
            formKeys.map(key => {
                if (key == 'plsch_messen_scope') form[key] = data[key].split(",");
                else form[key] = data[key];
            })
            this.noticeForm = { ...form };
            this.noticeDialog = true;
        },
        /** 确认添加 */
        handleConfirm(type) {
            this.$refs.noticeForm.validate(async (valid) => {
                if (valid) {
                    this.btnload[type - 1] = true;
                    this.$forceUpdate();
                    let params = {
                        ...this.noticeForm,
                        type,
                        plsch_messen_scope: this.noticeForm.plsch_messen_scope.join(","),
                    };
                    console.log(params);
                    let res;
                    if (params.plsch_messen_id) {
                        res = await messageEdit(params)
                    } else {
                        res = await messageAdd(params)
                    }
                    this.btnload[type - 1] = false;
                    this.$forceUpdate();
                    if (res) {
                        this.hideDialog();
                        this.$message.success(type == 1 && '发布成功' || params.plsch_messen_id && "编辑成功" || "添加成功");
                        this.getData();
                    }
                }
            });
        },
        /** 删除通知 */
        messageRemove(id) {
            this.$msgbox({
                title: '删除',
                message: '确认删除当前通知？',
                type: 'warning',
                showClose: false,
                showCancelButton: true,
                cancelButtonClass: 'el-button--custom_info',
                confirmButtonClass: 'el-button--custom_primary',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(async () => {
                const res = await messageRemove(id);
                if (res.status == 200) {
                    this.$message({
                        type: 'success',
                        duration: 1500,
                        message: '通知已删除！'
                    });
                    this.tablePage.total = this.tablePage.total - 1 < 0 && 0 || this.tablePage.total - 1;
                    if (this.tableData.length == 1 && this.tablePage.pageindex != 1) {
                        this.tablePage.pageindex = this.tablePage.pageindex - 1;
                    }
                    this.getData();
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    duration: 1500,
                    message: '操作已取消'
                })
            });
        },
        /** 发布通知 */
        messagePublish(id) {
            this.$msgbox({
                title: '提示',
                message: '确认发布当前通知？',
                type: 'info',
                showClose: false,
                showCancelButton: true,
                cancelButtonClass: 'el-button--custom_info',
                confirmButtonClass: 'el-button--custom_primary',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(async () => {
                const res = await messagePublish(id);
                if (res.status == 200) {
                    this.$message({
                        type: 'success',
                        duration: 1500,
                        message: '通知已发布！'
                    });
                    this.getData();
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    duration: 1500,
                    message: '操作已取消'
                })
            });
        },
        /** 打开弹窗 */
        showDialog(){
            this.noticeDialog = true;
            this.$nextTick(()=>{
                this.$refs.noticeForm.clearValidate();
            })
        },
        /** 关闭弹窗 */
        hideDialog() {
            this.noticeDialog = false;
            this.$refs.noticeForm.resetFields();
            this.noticeForm = { plsch_messen_scope: [] };
        },
        /** 级联选择器数据切换 */
        cascaderChange(val, ref) {
            if (this.$isEmpty(val)) {
                this.$nextTick(() => {
                    this.$refs[ref].$refs.panel.clearCheckedNodes();
                    this.$refs[ref].$refs.panel.activePath = [];
                })
            }
        }
    },
}
</script>